define("ember-websockets/services/websockets", ["exports", "@ember/service", "@ember/object", "ember-websockets/helpers/websocket-proxy", "ember-websockets/helpers"], function (_exports, _service, _object, _websocketProxy, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let WebSocketService = _exports.default = (_dec = (0, _object.computed)(), (_class = class WebSocketService extends _service.default {
    /*
      A hash of open websocket connections. This
      allows multiple components to share the same connection.
       {
        'websocket-url': WebSocket Proxy object
      }
    */
    get sockets() {
      return {};
    }

    /*
      socketFor returns a websocket proxy object. On this object there is a property `socket`
      which contains the actual websocket object. This websocket object is cached based off of the url meaning
      multiple requests for the same socket will return the same object.
    */
    socketFor(url, protocols = []) {
      /*
        Websockets allows either a string or array of strings to be passed as the second argument.
        Normalize both cases into an array of strings so we can just deal with arrays.
      */
      if (typeof protocols === 'string') {
        protocols = [protocols];
      }

      /*
        Normalize the url as native websockets add a / to the end of the url:
        http://example.com:8000 becomes: http://example.com:8000/
         Since the url will be used as a key will need to make sure that it does not
        contain '.' as it will throw ember off
      */
      const normalizedUrl = (0, _helpers.normalizeURL)(url);
      const cleanedUrl = (0, _helpers.cleanURL)(normalizedUrl);

      /*
       */
      const existingProxy = (0, _object.get)(this, `sockets.${cleanedUrl}`);
      if (existingProxy && this.isWebSocketOpen(existingProxy.socket)) {
        return existingProxy;
      }

      /*
        we can get to this place if the websocket has been closed and we are trying to reopen
        or we are creating a proxy for the first time
      */
      const newWebSocket = this.createSocket(normalizedUrl, protocols);
      if (existingProxy) {
        /*
          If there is an existing socket in place we simply update the websocket object and not
          the whole proxy as we dont want to destroy the previous listeners.
        */
        (0, _object.set)(existingProxy, 'socket', newWebSocket);
        return existingProxy;
      }
      const newProxy = this.createProxy(newWebSocket, protocols);
      (0, _object.set)(this, `sockets.${cleanedUrl}`, newProxy);
      return newProxy;
    }
    closeSocketFor(url) {
      const cleanedUrl = (0, _helpers.cleanURL)((0, _helpers.normalizeURL)(url));
      const existingSocket = (0, _object.get)(this, `sockets.${cleanedUrl}`);
      if (existingSocket) {
        existingSocket.socket.close();
      }
      delete this.sockets[cleanedUrl];
    }
    isWebSocketOpen(websocket) {
      return websocket.readyState !== WebSocket.CLOSED;
    }
    createSocket(url, options) {
      return new WebSocket(url, options);
    }
    createProxy(socket, protocols) {
      return _websocketProxy.default.create({
        content: this,
        protocols,
        socket
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sockets", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sockets"), _class.prototype)), _class));
});