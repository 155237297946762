define("ember-svg-jar/inlined/nav.devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"currentColor\"><path d=\"M19.276 6.566v9.714h-.737V6.566c0-.61-.496-1.105-1.105-1.105H6.566c-.61 0-1.105.496-1.105 1.105v10.868c0 .61.496 1.105 1.105 1.105h10.868c.61 0 1.105-.496 1.105-1.105 0-.029.004-1.274.01-1.302a.369.369 0 01.72.014c.005.023.007 1.264.007 1.288a1.844 1.844 0 01-1.842 1.842H6.566a1.844 1.844 0 01-1.842-1.842V6.566c0-1.016.826-1.842 1.842-1.842h10.868c1.016 0 1.842.826 1.842 1.842zm3.408 5.066H20.75a.368.368 0 000 .736h1.934a.368.368 0 000-.736zM20.75 8.684h1.934a.368.368 0 000-.736H20.75a.368.368 0 000 .736zm1.934 6.632H20.75a.368.368 0 000 .736h1.934a.368.368 0 000-.736zM3.25 11.632H1.316a.368.368 0 000 .736H3.25a.368.368 0 000-.736zm0 3.684H1.316a.368.368 0 000 .736H3.25a.368.368 0 000-.736zm0-7.368H1.316a.368.368 0 000 .736H3.25a.368.368 0 000-.736z\"/><path d=\"M14.427 13.93a1.49 1.49 0 00-1.488 1.488c0 .82.667 1.488 1.488 1.488a1.49 1.49 0 001.488-1.488 1.49 1.49 0 00-1.488-1.489zm0 2.496a1.01 1.01 0 010-2.017 1.01 1.01 0 010 2.017zM9.573 13.93a1.49 1.49 0 00-1.488 1.488c0 .82.668 1.488 1.488 1.488a1.49 1.49 0 001.488-1.488 1.49 1.49 0 00-1.488-1.489zm0 2.496a1.01 1.01 0 010-2.017 1.01 1.01 0 010 2.017zm1.552-8.406h2.761a1.037 1.037 0 010 2.072h-2.761V8.02zm0 2.417h2.761c.762 0 1.381-.62 1.381-1.38 0-.762-.62-1.381-1.38-1.381h-2.762v2.761z\"/><path d=\"M12.875 8.02h-2.761a1.037 1.037 0 000 2.072h2.761V8.02zm0 2.417h-2.761c-.762 0-1.381-.62-1.381-1.38 0-.762.62-1.381 1.38-1.381h2.762v2.761z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});