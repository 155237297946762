define("ember-websockets/services/socket-io", ["exports", "ember-websockets/services/websockets", "ember-websockets/helpers/socketio-proxy"], function (_exports, _websockets, _socketioProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SocketIOService extends _websockets.default {
    isWebSocketOpen(socket) {
      return socket.io.readyState !== 'closed';
    }
    createSocket(url, options = {}) {
      const newSocketIO = io(url, options);
      newSocketIO.connect();
      return newSocketIO;
    }
    createProxy(socket) {
      return _socketioProxy.default.create({
        content: this,
        socket
      });
    }
  }
  _exports.default = SocketIOService;
});