define("ember-svg-jar/inlined/en-uk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#f0f0f0\" d=\"M0 85.333h512V426.67H0z\"/><path fill=\"#d80027\" d=\"M288 85.33h-64v138.666H0v64h224v138.666h64V287.996h224v-64H288z\"/><path d=\"M393.785 315.358L512 381.034v-65.676zm-82.133 0L512 426.662v-31.474l-143.693-79.83zm146.982 111.304l-146.982-81.664v81.664z\" fill=\"#0052b4\"/><path fill=\"#f0f0f0\" d=\"M311.652 315.358L512 426.662v-31.474l-143.693-79.83z\"/><path fill=\"#d80027\" d=\"M311.652 315.358L512 426.662v-31.474l-143.693-79.83z\"/><path d=\"M90.341 315.356L0 365.546v-50.19zm110.007 14.154v97.151H25.491z\" fill=\"#0052b4\"/><path fill=\"#d80027\" d=\"M143.693 315.358L0 395.188v31.474l200.348-111.304z\"/><path d=\"M118.215 196.634L0 130.958v65.676zm82.133 0L0 85.33v31.474l143.693 79.83zM53.366 85.33l146.982 81.664V85.33z\" fill=\"#0052b4\"/><path fill=\"#f0f0f0\" d=\"M200.348 196.634L0 85.33v31.474l143.693 79.83z\"/><path fill=\"#d80027\" d=\"M200.348 196.634L0 85.33v31.474l143.693 79.83z\"/><path d=\"M421.659 196.636L512 146.446v50.19zm-110.007-14.154V85.331h174.857z\" fill=\"#0052b4\"/><path fill=\"#d80027\" d=\"M368.307 196.634L512 116.804V85.33L311.652 196.634z\"/>",
    "attrs": {
      "viewBox": "0 0 512 512"
    }
  };
});