define("ember-svg-jar/inlined/nav.home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M21.263 11.063l-9.367-8.697-9.366 8.697a.327.327 0 00.444.48l1.084-1.007v10.89h15.676v-10.89l1.084 1.006a.325.325 0 00.461-.017.326.326 0 00-.016-.462zm-11.326 9.71v-5.225c0-1.08.879-1.96 1.96-1.96 1.08 0 1.959.88 1.959 1.96v5.225h-3.92zm9.144 0h-4.572v-5.225a2.616 2.616 0 00-2.613-2.612 2.616 2.616 0 00-2.612 2.612v5.225H4.71V9.93l7.185-6.672L19.08 9.93v10.844z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});